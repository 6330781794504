@charset "UTF-8";
@use "../utility/variable" as var;

.sec-404 {
    padding: 80px 0 100px;
    @media only screen and (max-width: 767px) {
        padding: 6rem 0 7rem;
    }
    .ttl {
        font-size: 130px;
        color: var.$colorDbe;
        font-family: var.$fontQuat;
        line-height: 1em;
        margin-bottom: 35px;
        @media only screen and (max-width: 767px) {
            font-size: 10rem;
            margin-bottom: 3.5rem;
        }
        small {
            font-size: 24px;
            font-family: var.$fontQuat;
            display: block;
            line-height: 1em;
            letter-spacing: 0.05em;
            @media only screen and (max-width: 767px) {
                font-size: 2rem;
            }
        }
    }
    .txt {
        font-size: 14px;
        line-height: 1.6em;
        letter-spacing: 0.05em;
        @media only screen and (max-width: 767px) {
            font-size: 1.4rem;
        }
    }
}
